import {asFormData, asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    checkPassword: (data) => asJson(axios).post(getVerUri('users/profile/check-password'), data),
    changePassword: (data) => asJson(axios).post(getVerUri('users/profile/change-password'), data),
    showProfile: (data) => asJson(axios).get(getVerUri('users/profile/show'), {params: {...data}}),
    uploadAvatar: (data) => asFormData(axios).post(getVerUri('users/profile/upload-photo'), data),
    saveProfile: (data) => asJson(axios).post(getVerUri('users/profile/save'), data),
    changeEmail: (data) => asJson(axios).post(getVerUri('users/profile/change-email'), data),
})
