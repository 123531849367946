import {Type} from './Type'
import taskTypes from '@/utils/constants/tasks/taskTypes'
import {
    CLOSED_TASKS,
    FULLY_EXECUTED,
    PENDING_EXTERNAL_SIGNATURE,
    PENDING_INTERNAL_SIGNATURE
} from "@/domain/Entities/Task/taskStatuses";

export class Task extends Type {
    async #checkAndFetchEntity(id = undefined) {
        let entityId = super.store().state.tasks?.entity?.id
        if (id && parseInt(entityId) !== parseInt(id)) {
            await super.store().dispatch('fetchTaskPermissions', id)
        }
    }

    async reviewAndApproveView(id) {
        if (!id) {
            super.store().commit('setTaskEntity', {})
            return false
        }

        await this.#checkAndFetchEntity(id)
        const task = super.store().state.tasks.entity
        return task.type === taskTypes.CONTRACTS && !Object.values(CLOSED_TASKS).includes(task.status)
    }

    async sendForSignatureView(id) {
        if (!id) {
            super.store().commit('setTaskEntity', {})
            return false
        }

        await this.#checkAndFetchEntity(id)
        const task = super.store().state.tasks.entity
        return task.type === taskTypes.CONTRACTS
            && task.is_internal_assignee
            && task.contractApproved
    }

    async signView(id) {
        if (!id) {
            super.store().commit('setTaskEntity', {})
            return false
        }

        await this.#checkAndFetchEntity(id)
        const task = super.store().state.tasks.entity
        return task.type === taskTypes.CONTRACTS
            && (task.is_internal_assignee || task.is_signer)
            && [PENDING_EXTERNAL_SIGNATURE, PENDING_INTERNAL_SIGNATURE].includes(task.status)
    }

    async edit(id) {
        if (!id) {
            super.store().commit('setTaskEntity', {})
            return false
        }

        await this.#checkAndFetchEntity(id)
        return super.store().state.tasks.entity.edit
    }

    async view(id) {
        if (!id) {
            super.store().commit('setTaskEntity', {})
            return false
        }

        await this.#checkAndFetchEntity(id)
        return super.store().state.tasks.entity.view
    }

    async finishView(id) {
        if (!id) {
            super.store().commit('setTaskEntity', {})
            return false
        }

        await this.#checkAndFetchEntity(id)
        const task = super.store().state.tasks.entity
        return task.type === taskTypes.CONTRACTS
            && (
                task.is_internal_signer && task.status === FULLY_EXECUTED ||
                task.is_external_signer && [FULLY_EXECUTED, PENDING_INTERNAL_SIGNATURE].includes(task.status)
            )
    }

    async create() {
        return super.auth().can(super.permissions().TASKS_AND_DOCUMENTS_CREATE)
    }

    async bulkAction() {
        return !super.isSubPlan() && super.auth().can(super.permissions().TASKS_AND_DOCUMENTS_CREATE)
    }

    async communication() {
        return super.auth().can(super.permissions().TASKS_AND_DOCUMENTS_COMMUNICATION)
    }
}
