import {
    PENDING_ACKNOWLEDGEMENT,
    PENDING_APPROVAL,
    PENDING_EXTERNAL_SIGNATURE_GC_VIEW,
    PENDING_EXTERNAL_SIGNATURE_SUB_VIEW,
    PENDING_INTERNAL_SIGNATURE,
    PENDING_INTERNAL_SIGNATURE_SUB_VIEW,
    PENDING_SUBMISSION,
} from "@/domain/Entities/Task/taskStatuses";

const TYPES = {
    scope: {
        drafting: {
            background: '#2BB0E0',
            icon: '\uf4fc'
        },
        pending: {
            background: '#0081C2',
            icon: '\uf53d'
        },
        'not sent': {
            background: '#005694',
            icon: '\uf061'
        }
    },
    contract: {
        [PENDING_EXTERNAL_SIGNATURE_GC_VIEW]: {
            background: '#C3CDD5',
            icon: '\uf4fd'
        },
        [PENDING_EXTERNAL_SIGNATURE_SUB_VIEW]: {
            background: '#005694',
            icon: '\uf4fd'
        },
        [PENDING_INTERNAL_SIGNATURE]: {
            background: '#005694',
            icon: '\uf058'
        },
        [PENDING_INTERNAL_SIGNATURE_SUB_VIEW]: {
            background: '#C3CDD5',
            icon: '\uf058'
        }
    },
    task: {
        [PENDING_SUBMISSION]: {
            background: '#9FAFBC',
            icon: '\uf783',
        },
        [PENDING_APPROVAL]: {
            background: '#0081C2',
            icon: '\uf274',
        },
        [PENDING_ACKNOWLEDGEMENT]: {
            background: '#C3CDD5',
            icon: '\uf06e',
        }
    }
}

function handleItems(arr) {
    if (arr && arr.length) {
        const total = arr.reduce((a, v) => a + v);
        return arr.map(v => Math.max(v / total * 100, 3.5));
    }

    return arr
}

function chartAdapter(item) {
    const chartSetting = TYPES[item.slug] || null
    let backgrounds = []
    let icons = []

    if (chartSetting) {
        item.items.forEach(item => {
            if (chartSetting[item.status]) {
                backgrounds.push(chartSetting[item.status]['background'])
                icons.push(chartSetting[item.status]['icon'])
            }
        })
    }

    return {
        title: item.title,
        slug: item.slug,
        total: item.total,
        open: item.open,
        overdue: item.overdue,
        data: handleItems(item.items?.map((el) => el.total)),
        additionalData:
            item.items?.map((el) => ({
                title: el.status,
                total: el.total,
                open: el.open,
                overdue: el.overdue,
            })) || [],
        backgroundColor: backgrounds,
        icons: icons
    }
}

function sectionAdapter(section) {
    return {
        title: section.title,
        slug: section.slug,
        items: section.items?.map((el) => chartAdapter(el)) || [],
        option: section.option
    }
}

export function dashboardAdapter(data) {
    return data.map((el) => sectionAdapter(el)) || []
}
