<template>
    <ConfirmDialog ref="confirmDialog"/>
    <div class="companyList">
        <h1 class="companyList__title"> Manage Companies </h1>
        <CCard class="companyList-card">
            <CCardBody class="companyList-card-body">
                <div class="companyList-filter">
                    <div class="GCFilterMaxWidth w-100">
                        <GCFilter component="SuperAdminCompany" @search="(val) => fetchData(val)"/>
                    </div>
                    <CreateAccountButton/>
                </div>
                <SmartTable :items="companies" :items-per-page="parseInt(perPage)" :columns="fields"
                            :sorter-value="sort" :loading="loadingTable" :active-page="activePage" :per-page="perPage"
                            :pages="pages" :clear-text=" search.length ? 'No search results found' : 'No Data yet'"
                            @sorter-change="onSort" @update-active-page="(value) => (activePage = value)"
                            @update-per-page="(value) => (perPage = value)">
                    <template #button="{ item }">
                        <div class="companyList__actions">
                            <button data-action="details" title="View company details"
                                    @click="$router.push({name: 'SuperAdminCompanyDetails',params: { id: item.id }})">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                     fill="#000000" width="24" height="24" version="1.1" id="Capa_1"
                                     viewBox="0 0 330 330" xml:space="preserve">
                                    <g>
                                        <path
                                            d="M165,0C74.019,0,0,74.02,0,165.001C0,255.982,74.019,330,165,330s165-74.018,165-164.999C330,74.02,255.981,0,165,0z    M165,300c-74.44,0-135-60.56-135-134.999C30,90.562,90.56,30,165,30s135,60.562,135,135.001C300,239.44,239.439,300,165,300z"/>
                                        <path
                                            d="M164.998,70c-11.026,0-19.996,8.976-19.996,20.009c0,11.023,8.97,19.991,19.996,19.991   c11.026,0,19.996-8.968,19.996-19.991C184.994,78.976,176.024,70,164.998,70z"/>
                                        <path
                                            d="M165,140c-8.284,0-15,6.716-15,15v90c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15v-90C180,146.716,173.284,140,165,140z   "/>
                                    </g>
                                </svg>
                            </button>
                            <button data-action="block" v-if="item.status !== 'blocked'" title="Block company"
                                    @click="onCompanyBlock(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                    <g clip-path="url(#clip0_6_3388)">
                                        <path
                                            d="M7 11H17V13H7V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                                            fill="#323232"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6_3388">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                            <button data-action="unblock" v-else title="Unblock company"
                                    @click="onCompanyUnblock(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M5.63604 18.364C9.15076 21.8787 14.8492 21.8787 18.364 18.364C21.8787 14.8492 21.8787 9.15076 18.364 5.63604C14.8492 2.12132 9.15076 2.12132 5.63604 5.63604C2.12132 9.15076 2.12132 14.8492 5.63604 18.364ZM7.80749 17.6067C10.5493 19.6623 14.4562 19.4433 16.9497 16.9497C19.4433 14.4562 19.6623 10.5493 17.6067 7.80749L14.8284 10.5858C14.4379 10.9763 13.8047 10.9763 13.4142 10.5858C13.0237 10.1953 13.0237 9.5621 13.4142 9.17157L16.1925 6.39327C13.4507 4.33767 9.54384 4.55666 7.05025 7.05025C4.55666 9.54384 4.33767 13.4507 6.39327 16.1925L9.17157 13.4142C9.5621 13.0237 10.1953 13.0237 10.5858 13.4142C10.9763 13.8047 10.9763 14.4379 10.5858 14.8284L7.80749 17.6067Z"
                                          fill="currentColor"/>
                                </svg>
                            </button>
                            <button data-action="delete" title="Delete company" @click="onCompanyDelete(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                     fill="#000000" version="1.1" id="Capa_1" width="24" height="24"
                                     viewBox="0 0 482.428 482.429" xml:space="preserve">
                                    <g>
                                        <g>
                                            <path
                                                d="M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098    c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117    h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828    C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879    C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096    c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266    c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979    V115.744z"/>
                                            <path
                                                d="M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07    c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z"/>
                                            <path
                                                d="M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07    c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z"/>
                                            <path
                                                d="M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07    c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z"/>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </template>
                    <template #owner="{ item }">
                        <div v-for="(owner, k) in item.owners.slice(0, 2)" :key="k">
                            {{ owner.name }}
                            <span v-if="item.owners.length > 1 && k === 0">,&nbsp;&nbsp;</span>
                        </div>
                        <span v-if="item.owners.length > 2">&nbsp;...</span>
                    </template>
                </SmartTable>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import table from '@/mixin/table'
import CreateAccountButton from './CreateAccountButton'
import GCFilter from '@/components/GCFilter/index'
import ConfirmDialog from "@/components/Modals/ConfirmDialog.vue";

const clearFilterData = {
    company_name: '',
    owner_name: '',
    creation_date_from: null,
    creation_date_to: null,
    users_count_from: null,
    users_count_to: null,
    projects_count_from: null,
    projects_count_to: null,
    contracts_count_from: null,
    contracts_count_to: null,
    last_activity_from: null,
    last_activity_to: null,
}

export default {
    components: {ConfirmDialog, CreateAccountButton, GCFilter},
    inject: ['toast'],
    mixins: [table],
    data: (vue) => ({
        search: '',
        companies: [],
        filter: {...clearFilterData},
        fields: [
            {
                key: 'name',
                label: 'Company Name',
                _style: '',
                link: {
                    name: 'SuperAdminCompanyDetails',
                    params: {id: 'id'},
                    iconNewTab: true,
                },
            },
            {key: 'owner', label: 'Owner', sorter: false},
            {
                key: 'created_at',
                label: 'Creation date',
                format: (v) => vue.$BtoF({value: v}),
            },
            {key: 'users_count', label: 'Users', format: (v) => `${v}`},
            {key: 'projects_count', label: 'Projects', format: (v) => `${v}`},
            {
                key: 'last_activity',
                label: 'Last activity',
                format: (v) => (v ? vue.$BtoF({value: v}) : 'N/A'),
            },
        ],
    }),

    methods: {
        onCompanyDelete(company) {
            this.$refs.confirmDialog
                .confirm({
                    text: `Are you sure you want to delete company ${company.name}? This action can not be undone`,
                    cancelText: 'Cancel',
                    confirmText: 'Confirm',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        this.$http.superadmin.deleteCompany(company.id)
                            .then(res => {
                                this.fetchData();
                                this.toast('info', res.data.message)
                            })
                            .catch(() => this.toast('warning', `Error`))
                    }
                })
        },
        onCompanyUnblock(company) {
            this.$refs.confirmDialog
                .confirm({
                    text: `Are you sure you want to unblock company ${company.name}?`,
                    cancelText: 'Cancel',
                    confirmText: 'Confirm',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        this.$http.superadmin.blockCompany(company.id)
                            .then(res => {
                                this.fetchData();
                                this.toast('info', res.data.message)
                            })
                            .catch(() => this.toast('warning', `Error`))
                    }
                })
        },
        onCompanyBlock(company) {
            this.$refs.confirmDialog
                .confirm({
                    text: `Are you sure you want to block company ${company.name}? You will be able to unblock the company again if required`,
                    cancelText: 'Cancel',
                    confirmText: 'Confirm',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        this.loadingTable = true
                        this.$http.superadmin.blockCompany(company.id)
                            .then(res => {
                                this.fetchData();
                                this.toast('info', res.data.message)
                            })
                            .catch(() => this.toast('warning', `Error`))
                    }
                })
        },
        clearFilter() {
            this.filter = {...clearFilterData}
        },
        makeParams(f = {}) {
            let params = {
                ...f,
                per_page: this.perPage,
                page: this.activePage,
                sort_field: this.sort && this.sort.column,
                sort_direction: this.sort && this.sort.state,
            }

            if (params.sort_field === 'last_activity') {
                params.sort_field = 'users_max_last_activity'
            }

            return params
        },
        fetchData(f = {}) {
            f = Object.assign(this.filter, f)
            this.loadingTable = true
            this.$http.superadmin
                .getCompanyList({params: this.makeParams(f)})
                .then((res) => {
                    this.companies = res.data.data
                    this.pages = res.data.last_page
                })
                .finally(() => (this.loadingTable = false))
        },
    },
}
</script>

<style lang="scss" scoped>
.companyList {
    width: 100%;
    height: calc(100vh - 190px);

    &__title {
        font-weight: 700;
        font-size: 28px;
        line-height: 37px;
        color: #303c54;
        margin-bottom: 20px;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        & [data-action="details"] {
            background: #0068ad;
        }

        & [data-action="block"] {
            background: orange;

            & svg path {
                fill: white;
            }
        }

        & [data-action="unblock"] {
            background: #20a91f;
        }

        & [data-action="delete"] {
            background: #fb3131;
        }

        & button {
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            border: none;
            padding: 5px;
            border-radius: 3px;

            //&:nth-child(1) {
            //  background: #0068ad;
            //}
            //
            //&:nth-child(2) {
            //  background: orange;
            //
            //  & svg path {
            //    fill: white;
            //  }
            //}
            //
            //&:nth-child(3) {
            //  background: #fb3131;
            //}
        }

        & svg {
            height: 16px;
            width: 16px;
            color: white;
            fill: white;
        }
    }

    &-card {
        height: 100%;
        background: none;

        &-body {
            background: #ffffff;
            box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.02);
            border-radius: 10px;
        }
    }

    &-filter {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 12px;

        @media (max-width: 767px) {
            flex-wrap: wrap-reverse;
        }
    }
}
</style>
